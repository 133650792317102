import { ToastContainer } from "react-toastify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "styles/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Script from "next/script";
import "styles/global.css";

function MyApp({ Component, pageProps }) {
  const GTM_ID = "GTM-P2SW2CC";
  // const tagManagerArgs = {
  //   gtmId: 'GTM-P2SW2CC'
  // }

  // useEffect(() => {
  //   TagManager.initialize(tagManagerArgs)
  // }, [])

  return (
    <>
      <Component {...pageProps} />
      <Script strategy="lazyOnload">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');`}
      </Script>
      <ToastContainer closeButton={false} />
    </>
  );
}

export default MyApp;
